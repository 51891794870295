@use './font.module.scss' as font;

@mixin t8xl {
    @include font.title-font;
    font-weight: 500;
    font-size: 52px;
    line-height: 60px;
}

@mixin t7xl {
    @include font.title-font;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
}

@mixin t6xl {
    @include font.title-font;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
}

@mixin t5xl {
    @include font.title-font;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
}

@mixin t4xl {
    @include font.title-font;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
}

@mixin t3xl {
    @include font.title-font;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
}

@mixin t2xl {
    @include font.title-font;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
}

@mixin txl {
    @include font.title-font;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

@mixin tlg {
    @include font.content-font;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

@mixin heading {
    @include font.content-font;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

@mixin headingSm {
    @include font.content-font;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.6;
}

@mixin detail {
    @include font.content-font;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

@mixin textBase {
    @include font.content-font;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
}

@mixin textBaseMedium {
    @include font.content-font;
    font-weight: 450;
    font-size: 1rem;
    line-height: 1.5;
}

@mixin textSm {
    @include font.content-font;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
}

@mixin textSmMedium {
    @include font.content-font;
    font-weight: 450;
    font-size: 0.875rem;
    line-height: 1.5;
}