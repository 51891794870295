@use 'src/styles/color.module.scss' as color;

.loading_spinner {
    width: 24px;
    height: 24px;
    color: color.$purple-700;
    transform-origin: center;
    animation:rotate 3s linear infinite;
    @keyframes rotate {
        from {transform: rotate(0deg);}
        to {transform: rotate(360deg);}
    }
    svg {
        width: 24px;
        height: 24px;
    }
}