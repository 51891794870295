@use 'src/styles/title.module.scss' as typography;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/input.module.scss' as it;

.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid color.$gray-200;
}

.modal_header_title {
  @include typography.headingSm;
  margin: 0;
}

.modal_header_button {
  @include bt.icon_button;
}

.modal_body {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1rem;
  @include typography.textSm;
}

.modal_actions {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}