@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/content.module.scss' as ctt;

.button_container {
    display: flex;
}

.button {
    background: none;
    border: none;
    cursor: pointer;
    color: color.$gray-600;
    font-size: inherit;
    padding: 0;
    margin-right: 12px;
}

.selected {
    color: color.$purple-900;
}

.horizontal_divider {
    height: 1px;
    background-color: #ccc;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.description_wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    &:last-child {
        margin-bottom: 0;
    }
    @media (min-width: bp.$sm) {
        margin-bottom: 32px;
    }
}

.description_title {
    @include title.detail;
    color: color.$gray-900;
    font-weight: 450;
    margin-bottom: 12px;
}

.description_value {
    @include title.detail;
    line-height: 24px;
    color: color.$gray-900;
    word-wrap: break-word;
    border: 1px solid color.$gray-200;
    border-radius: 8px;
    overflow-y: auto;
    width: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}

.description_value_button_wrapper {
    padding: 12px 16px;
    border-bottom: 1px solid color.$gray-200;
}

.description_value_content_wrapper {
    width: 100%;
    padding: 8px 12px 8px 12px;
}

.description_text {
}

.why_goods {
    @include title.detail;
    flex: 1;
    white-space: pre-wrap;
    word-wrap: break-word;
    border-radius: 4px;
    overflow-y: auto;
    color: color.$gray-500;
    min-height: 110px;
    font-size: 13px;
    line-height: 18px;
}

.why_goods_list {
    list-style-type: none;
    padding: 0;
    margin-top: 0px;
}
