@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/content.module.scss' as ctt;

.how {
    @include ctt.content;

    padding: 0px 16px 80px;
    flex-wrap: wrap;

    @media (min-width: bp.$sm) {
        padding: 0px 32px 96px;
    }

    @media (min-width: bp.$md) {
        padding: 0px 48px 96px;
        justify-content: left;
    }

    @media (min-width: bp.$xl) {
        padding: 0px 64px 96px;
    }
}

.how_header {
    max-width: 640px;

    @media (min-width: bp.$lg) {
        text-align: center;
    }

    @media (min-width: bp.$md) {
        width: 100%;
        max-width: initial;
    }
}

.how_header_title {
    @include title.t6xl;

    @media (min-width: bp.$xl) {
        @include title.t7xl;
    }

    color: color.$gray-900;
}

.how_header_desc {
    @include title.tlg;

    margin-top: 16px;
    color: color.$gray-600;
}

.how_content {
    width: 100%;
    display: flex;
    margin-top: 80px;
    gap: 32px;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: bp.$md) {
        justify-content: left;
    }

    @media (min-width: bp.$lg) {
        flex-wrap: nowrap;
    }

    flex-direction: row-reverse;
}

.how_content_img {
    width: 100%;
    max-width: 552px;

    @media (min-width: bp.$lg) {
        max-width: 448px;
    }

    @media (min-width: bp.$xl) {
        max-width: 552px;
    }

    img {
        width: 100%;
    }
}

.how_content_card {
    padding: 0 16px;
    width: 100%;
    max-width: 552px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (min-width: bp.$md) {
        max-width: initial;
    }
}

.how_content_card_numBtn {
    @include bt.number_button;
}

.how_content_card_title {
    @include title.t4xl;

    color: color.$gray-900;

    margin-top: 20px;
}

.how_content_card_desc {
    @include title.tlg;

    color: color.$gray-600;

    margin-top: 12px;
    line-height: 26px;
}
