@use 'src/styles/color.module.scss' as color;
@use 'src/styles/breakpoint.module.scss' as bp;

.LogoDialog{
    border: 1px solid color.$gray-200;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    width: 480px;
    height: auto;
    background-color: #FFFFFF;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (max-width: bp.$xs) {
        width: 100%;
    }
}

.LogoDialog_logo{
    width: 74.4px;
    height: 28px;
}