@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/content.module.scss' as ctt;

.footer_wrapper {
    background-color: #faf8fc;
}

.footer {
    @include ctt.content;

    padding: 80px 16px;
    flex-wrap: wrap;
    gap: 48px;
    align-items: flex-start; /* Add this line */

    @media (min-width: bp.$sm) {
        padding: 96px 32px;
    }

    @media (min-width: bp.$md) {
        padding: 96px 48px;
    }

    @media (min-width: bp.$lg) {
        padding: 96px 32px;
        flex-wrap: nowrap;
    }

    @media (min-width: bp.$xl) {
        padding: 96px 64px;
    }
}

.footer_content {
    width: 100%;

    @media (min-width: bp.$lg) {
        width: calc(33% - 21px);
    }

    @media (min-width: bp.$xl) {
        width: calc(33% - 32px);
    }

    a {
        margin-top: 8px;
        display: block;
        font-size: 16px;
        line-height: 24px;
        color: color.$gray-600;
    }

    p {
        color: color.$gray-600;
        margin-top: 8px;
        margin-bottom: 16px;
    }
}

.footer_content_title {
    @include title.txl;
    color: color.$gray-900;
    margin-bottom: 16px;
}

.footer_content_primary_btn {
    @include bt.primary_button;
}

.logo {
    width: 86px; /* Set your desired width */
    height: auto; /* Set your desired height */
}

.footer_contact {
    padding-top: 8px;
}
