@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/content.module.scss' as ctt;

.hero_wrapper {
    background: linear-gradient(180deg, rgba(234, 227, 242, 0) 0%, #faf8fc 100%),
        #ffffff;
}

.hero {
    @include ctt.content;

    padding: 40px 16px 0 16px;
    gap: 32px;
    flex-wrap: wrap;

    @media (min-width: bp.$sm) {
        padding: 48px 32px 0 32px;
    }

    @media (min-width: bp.$md) {
        padding: 48px;
    }

    @media (min-width: bp.$lg) {
        flex-wrap: nowrap;
    }

    @media (min-width: bp.$xl) {
        padding: 48px 64px;
    }
}

.hero_desc {
    width: 100%;
    justify-content: center;
}

.hero_desc_title {
    @include title.t5xl;
    text-align: center;

    color: color.$gray-900;
    margin: auto;

    span {
        color: color.$purple-900;
    }

    @media (max-width: bp.$sm) {
        text-align: left;
    }

    @media (min-width: bp.$sm) {
        @include title.t6xl;
        max-width: 576px;
    }

    @media (min-width: bp.$md) {
        @include title.t7xl;
        max-width: 672px;
    }

    @media (min-width: bp.$lg) {
        @include title.t7xl;
        max-width: 928px;
    }

    @media (min-width: bp.$xl) {
        @include title.t8xl;
        max-width: 960px;
    }
}

.hero_desc_detail {
    @include title.tlg;

    text-align: center;
    color: color.$gray-600;
    margin: 20px auto;

    @media (max-width: bp.$sm) {
        text-align: left;
    }

    @media (min-width: bp.$sm) {
        width: 576px;
    }

    @media (min-width: bp.$md) {
        width: 640px;
    }
}

.hero_desc_buttons {
    gap: 8px;
    margin-top: 32px;
    justify-content: center;
    text-align: center;

    @media (min-width: bp.$sm) {
        gap: 12px;
    }
    & > div {
        max-width: 480px;
        width: 100%;
        
        form {
            width: auto;
        }

        input {
            box-sizing: border-box;
            width: 100%;
            border: 1px solid color.$gray-200;
        }
    }
}