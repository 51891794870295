@use 'src/styles/content.module.scss' as ctt;
@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/breakpoint.module.scss' as bp;

.descriptions_wrapper {
    @include ctt.content;
    flex-direction: column;
}

.callback {
    @include ctt.content;
    min-height: 100vh;

    .wrapper {
        border: 1px solid color.$gray-200;
        border-radius: 8px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        width: 480px;
        height: auto;
        background-color: #ffffff;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;

        @media (max-width: bp.$xs) {
            width: 100%;
        }
    }

    .loading {
        width: 48px;
        height: 48px;

        svg {
            width: 48px;
            height: 48px;
        }
    }

    .message {
        @include title.heading;
        font-size: 18px;
        color: color.$gray-600;
        text-align: center;
    }
}
