@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/content.module.scss' as ctt;


.login_wrapper{
    background-color: #F5F5F5;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}
.login_vwrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.login, .check_email {
    border: 1px solid color.$gray-200;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    width: 480px;
    height: 393px;
    background-color: #FFFFFF;
    padding: 32px;

    display: flex;
    flex-direction: column;
    gap: 32px;
    height: auto;

    &.check_email {
        gap: 24px;
    }
}

.check_email_desc {
    @media (max-width: bp.$xs) {
        max-width: 250px;
    }
}

.check_email_desc_title {
    @include title.txl;
    line-height: 28px;
    color: color.$gray-900;
}

.check_email_desc_content {
    margin-top: 16px;
    @include title.detail;
    color: color.$gray-600;
}
.resend {
    @media (max-width: bp.$xs) {
        max-width: 250px;
    }

    p {
        @include title.detail;
        color: color.$gray-900;
        font-weight: 450;

        &:last-child {
            margin-top: 4px;
            color: color.$gray-600;
            font-weight: 400;
            a {
                color: color.$purple-900;
                cursor: pointer;
            }
        }
    }
}


.login_title {
    @include title.tlg;

    font-weight: 500;
    margin-top:-12px;
}
.login_form {
    width: 100%;
}

.login_form_title {
    @include title.detail;
    font-weight: 450;
    margin-bottom: 12px;
}

.login_form_input {
    border: 1px solid color.$gray-200;
    border-radius: 8px;
    width: 100%;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.05);
    padding: 8px 12px;
    margin-bottom: 24px;
    box-sizing: border-box;
    &:focus-visible  {
        outline: none;
    }
}

.login_button {
    @include bt.primary_button;

    width: 100%;
    background-color: color.$purple-900;
    border: #330476;
    font-size: 14px;
    height: 32px;
    line-height: 16px;

    &:disabled {
        background-color: color.$purple-200;
    }
}

.divider {
    border-bottom: 1px solid color.$gray-200;
}

.login_signup_wrapper {
    @include title.detail;

    p {
        margin-bottom: 12px;
    }

    a {
        color: color.$purple-900;
        font-weight: 450;
    }
}
.logo {
    width: 74.4px;
    height: 28px;
}

.tos {
    @include title.detail;

    font-size: 13px;
    color: color.$gray-600;

    @media (max-width: bp.$xs) {
        max-width: 250px;
    }

    a {
        margin-left: 5px;
        color: color.$gray-600;
        text-decoration: underline;
    }
}