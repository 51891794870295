@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/content.module.scss' as ctt;

.description_index {
    max-width: 300px;
    max-height: 100vh;
    overflow-y: scroll;
}

.description_index h3 {
    margin-top: 0;
}

.description_index ul {
    list-style-type: none;
    padding: 0;
}

.description_index li {
    margin-bottom: 10px;
}

// PropertyIndex.scss

.property-index {
    display: flex;
    flex-direction: column;
}

.property_index_wrapper {
    padding: 0px 12px 0px 12px;
}
:global(.properties_list_div) { 
    .property_index_wrapper{
        padding: 0;
    }
}

.icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: color.$gray-500;
}

.search_box {
    display: flex;
    align-items: center;
    border: 1px solid color.$gray-200;
    border-radius: 8px;
    padding: 8px;
    margin: 20px 24px 16px 24px;
}

.input {
    border: none;
    outline: none;
    flex-grow: 1;
}
