@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/content.module.scss' as ctt;
@use 'src/styles/title.module.scss' as typography;
@use 'src/styles/input.module.scss' as it;
@use 'src/styles/modal.module.scss' as modal;

.user_settings {
    @include typography.textSm;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    max-width: 640px;
    padding: 1.5rem 2rem;

    @media (max-width: bp.$xs) {
        padding: 1.5rem 1rem;
    }
}

.settings_title {
    @include typography.tlg;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.settings_section {
    display: flex;
    flex-direction: column;
    align-items: left;
    border-radius: 8px;
    border: 1px solid color.$gray-200;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.08);
}


.settings_section_header {
    @include typography.headingSm;
    border-bottom: 1px solid color.$gray-200;
    padding: 16px;
}

.settings_section_body {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1.25rem;
}

.settings_setting {
    align-items: left;
}

.settings_setting_title {
    @include typography.textSmMedium;
    color: color.$gray-600;
}

.settings_setting_detail {
    @include typography.textSmMedium;
    padding-top: 0.25rem;

    a {
        color: color.$gray-900;
    }
}

.settings_button {
    @include bt.secondary_button;
    @include bt.button_sm;
    margin-right: 12px;
}

.settings_change_plan_button {
    @include bt.primary_button;
    @include bt.button_sm;
    margin-right: 12px;
}

.settings_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
}

.settings_modal_wrapper {
    width: 480px;

    @media (max-width: bp.$sm) {
        width: 350px;
    }
}

.settings_modal_title {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
}

.settings_modal_text {
    @include typography.detail;
    font-weight: 500;
    line-height: 36px;
}

.settings_modal_x {
    width: 36px;
    height: 36px;
    justify-content: center;
    display: flex;
    padding: 12px 0;
}

.settings_modal_line {
    border-bottom: 1px solid color.$gray-200;
}

.settings_modal_desc {
    @include typography.detail;
    padding: 16px;
}

.settings_modal_buttons {
    text-align: right;
    padding: 8px 4px 16px 16px;
}

.settings_modal_overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
}