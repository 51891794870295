@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/content.module.scss' as ctt;
@use 'src/styles/title.module.scss' as title;

.container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 70px);
    width: 100%;
}

.property_index {
    flex: 1;
    max-width: 360px;
    min-width: 360px;
    height: 100%;
    border-right: 1px solid color.$gray-200;
    overflow-y: auto; /* Enable vertical scrolling when content exceeds height */
}

.property_description {
    flex: 3;
    width: calc(100% - 360px);
    height: 100%;
    padding-top: 24px; 
    padding-left: 32px;
    overflow-y: auto; /* Enable vertical scrolling when content exceeds height */
}

.info_panel {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    margin: 20px;
    padding: 20px;
    // min-height: 200px;
    width: calc(100% - 40px);
    max-width: 800px;
}

.info_panel_message {
    @include title.heading;
    color: color.$gray-600;
}