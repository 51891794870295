@use 'src/styles/color.module.scss' as color;
@use 'src/styles/title.module.scss' as title;

.billing_type_radio_wrapper {
    margin-top: 12px;
    .billing_type_radio {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        gap: 16px;

        .radio_active {
            border-color: color.$purple-400;
            background-color: color.$purbple-050-v2;
        }
    }

    :global(.MuiFormControlLabel-root) {
        width: calc(50% - 8px);
        border: 1px solid color.$gray-200;
        border-radius: 8px;
        margin: 0;
        svg {
            width: 16px;
            height: 16px;
        }
        span {
            @include title.detail;
        }
        span:global(.MuiRadio-colorPrimary) {
            color: color.$gray-600;
            &:hover {
                background-color: rgba(78, 10, 148, 0.04);
            }
        }

        span:global(.Mui-checked) {
            color: color.$purple-900;
        }
    }
}