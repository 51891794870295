@use './color.module.scss' as color;
@use './title.module.scss' as typography;


@mixin input {
    @include typography.textSm;
    padding: 0.5rem 0.75rem;
    border: 1px solid color.$gray-200;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.08);
    border-radius: 0.5rem;
    cursor: pointer;


    &:hover {
        border-color: color.$gray-300;
    }

    &:focus-within {
        border-color: color.$purple-600;
        box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.08), 0 0 0 2px color.$purple-200;
        outline: none;
    }
}

.form_element {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.form_label {
    @include typography.textSmMedium;
}

.form_control {
    display: flex;
    align-items: center;
}

.form_control input {
    @include input;
    width: 100%;
    cursor: auto;
}

.form_description {
    display: flex;
    gap: 0.5rem;
    align-items: baseline;
    @include typography.textSm;
    color: color.$gray-600;
}

.form_error {
    display: flex;
    gap: 0.5rem;
    align-items: baseline;
    @include typography.textSm;
    color: color.$red-600;
}