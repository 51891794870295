@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/content.module.scss' as ctt;
@use 'src/styles/input.module.scss' as it;

.url_input_wrapper {
    margin-left: auto; 
    margin-right: auto; 
}

.form_container {
    width: 100%;
}

.hero_desc_input {
    @include it.input;
    width: 100%;
    margin-top: 12px;
}

.hero_desc_primary_btn {
    @include bt.primary_button;
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    margin-top: 12px;
}

.sparkle_icon {
    width: 14px;
    height: 14px;
    color: #fcd34d;
    margin-right: 10px;
}

.error_message {
    color: red;
    font-size: 14px;
    margin-top: 8px;
}
