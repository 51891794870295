$purple-050: #e2e8f0;
$purple-100: #f0ebf6;
$purple-200: #dacde9;
$purple-400: #a382c7;
$purple-500: #8b5cf6;
$purple-600: #7C3AED;
$purple-700: #6D28D9;
$purple-800: #5b21b6;
$purple-900: #4e0a94;
$purple-950: #2e1065;

$purbple-050-v2: #faf8fc;

$gray-100: #F5F5F5;
$gray-200: #e5e5e5;
$gray-300: #D4D4D4;
$gray-500: #737373;
$gray-600: #525252;
$gray-700: #404040;
$gray-900: #171717;

$red-600: #dc2626;

// Annotation colors
$anno-blue: #0ea5e9;
$anno-blue-light: #e0f2fe;
$anno-purple: #8b5cf6;
$anno-purple-light: #ede9fe;
$anno-red: #f43f5e;
$anno-red-light: #ffe4e6;
$anno-orange: #f59e0b;
$anno-orange-light: #fef3c7;