@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/content.module.scss' as ctt;
@use 'src/styles/title.module.scss' as title;

.topbar {
    @include ctt.content;

    max-width: 100%;
}

.tabar_wrapper {
    max-width: 100%;
    width: 100%;
    display:block;
    border-bottom: 1px solid color.$gray-200;
}

.topbar_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 32px; 
}

.logo {
    margin: 0;
    padding: 0;
    line-height: 0;
    width: 86px;
    height: auto;
}

.tab {
    @include title.heading;
    padding: 24px 24px;
    border: transparent;
    transition: border-color 0.3s;
    background-color: transparent;

    &.active {
        border-bottom: 2px solid;
        border-color: color.$purple-900;
        color: color.$gray-900;
    }
    &.inactive {
        color: color.$gray-600;
    }

    &:hover {
        cursor: pointer;
    }
}

.icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.topbar_logout {
    button {
        @include bt.secondary_button;
    }
}
