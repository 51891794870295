@use './color.module.scss' as color;
@use './font.module.scss' as font;
@use './title.module.scss' as typography;

@mixin button {
    @include typography.textBaseMedium;
    padding: 8px 12px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    cursor: pointer;
}

@mixin primary_button {
    @include button;
    color: #fff;
    border: 1px solid color.$purple-900;
    background-color: color.$purple-800;

    &:hover {
        background-color: color.$purple-900;
    }
}


@mixin secondary_button {
    @include button;
    color: color.$gray-900;
    border: 1px solid color.$gray-200;
    background-color: #fff;

    &:hover {
        border: 1px solid color.$gray-300;
        background-color: color.$gray-100;
    }
}

@mixin button_sm {
    @include typography.textSmMedium;
}

.button_primary {
    @include primary_button;
}

.button_secondary {
    @include secondary_button;
}

.button_sm {
    @include button_sm;
}

@mixin icon_button {
    @include typography.textSmMedium;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    color: color.$gray-600;
    border: none;
    border-radius: 0.5rem;
    background-color: #fff;

    &:hover {
        border: 1px solid color.$gray-300;
        background-color: color.$gray-100;
        color: color.$gray-900;
    }
}

@mixin number_button {
    @include font.content-font;

    background: linear-gradient(135deg,
            rgba(218, 205, 233, 0) 0%,
            rgba(218, 205, 233, 0.5) 100%),
        #f0ebf6;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 36px;
    height: 36px;
    border: 1px solid #f0ebf6;
    color: color.$purple-900;
    font-weight: 450;
    font-size: 18px;
    line-height: 26px;
}