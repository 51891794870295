html,
body {
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
}

.global_message_wrapper {
    position: absolute;
    width: auto;
    bottom: 60px;
    text-align: center;
    width: 100%;
    z-index: 1000;
    opacity: 0;
    display: block;
    transform: opacity 1s;
    
}

.global_message_wrapper.show{
    opacity: 1;
}
.global_message_wrapper .global_message {
    background-color: #DAEFE4;
    padding: 12px 16px;
    display: inline-block;
    border-radius: 8px;
    color: #18a058;
    font-weight: 500;
}
.global_message_wrapper .global_message.error {
    background-color: #F5DCE1;
    color: #d03050;
}
.global_message_wrapper .global_message.warning {
    background-color: #FCEFDA;
    color: #f0a020;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-thumb {
    background: #999; 
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888; 
}