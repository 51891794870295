@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/breakpoint.module.scss' as bp;

.total_summary {
    border: 1px solid color.$gray-200;
    border-radius: 8px;
    padding: 0 12px;

    .total_summary_choice,
    .total_summary_price {
        padding: 12px 0;
        span {
            @include title.detail;
        }
        span:last-child {
            @media (min-width: bp.$sm) {
                float: right;
            }
        }
    }

    .total_summary_price {
        border-top: 1px dashed color.$gray-200;
    }
}

.crossed_out {
    text-decoration: line-through;
    margin-right: 4px;
}

.span_order {
    display: flex;
}