@use 'src/styles/title.module.scss' as title;
@use 'src/styles/font.module.scss' as font;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/content.module.scss' as ctt;

.onboarding_wrapper {
    display: flex;
    justify-content: center;
    min-height: 100vh;
}

.onboarding_vwrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.onboarding {
    padding: 32px;
    background: #ffffff;
    border: 1px solid color.$gray-200;

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    max-width: 560px;

    @media (max-width: bp.$sm) {
        margin: 0 10px;
        width: 350px;
    }
}

.onboarding_title {
    @include title.txl;
    @include font.content-font;

    line-height: 28px;
}
.onboarding_subtitle {
    @include title.detail;
    color: color.$gray-600;
    margin-top: 12px;
    margin-bottom: 32px;
}

.onboarding_section {
    display: flex;
    gap: 16px;
    position: relative;
}

.onboarding_section_number {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    border: 1px solid color.$gray-200;
    padding: 4px 0;
    text-align: center;
    background-color: #ffffff;
    z-index: 1;

    &.onboarding_section_number__active {
        background-color: color.$purple-100;
        color: color.$purple-900;
        border-color: color.$purple-200;
    }
}

.onboarding_section_content {
    width: calc(100% - 32px);
    display: block;
    padding-top: 4px;
    padding-bottom: 24px;

    @media (max-width: bp.$sm) {
        width: calc(100% - 50px);
    }

    .onboarding_section_content_hidable__hidden {
        display: none;
    }

    .onboarding_section_content_title {
        @include title.heading;
        line-height: 24px;
        font-weight: 450;
    }

    .onboarding_section_content_title_gray {
        color: color.$gray-600;
        font-weight: 400;
    }

    .onboarding_section_content_subtitle {
        @include title.detail;
        margin-top: 8px;
        color: color.$gray-600;
    }

    .onboarding_section_content_input {
        margin-top: 12px;
        padding: 8px 12px;
        border: 1px solid color.$gray-200;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05),
            inset 0px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        width: 100%;
        box-sizing: border-box;
    }
    .onboarding_section_content_button {
        @include bt.primary_button;

        margin-top: 24px;
        width: 100%;
        font-size: 14px;
        line-height: 20px;

        &.loading_button {
            background-color: color.$purple-400;
            border-color: color.$purple-400;
            cursor: default;
        }

        .spinner_icon {
            display: inline-block;
            width: 16px;
            height: 18px;
            color: #ffffff;
            transform-origin: center;
            animation: rotate 3s linear infinite;

            @keyframes rotate {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(360deg);
                }
            }
        }
        .button_desc_primarty_btn_txt {
            margin-left: 8px;
        }
    }
}

.onboarding_section_vdivier {
    position: absolute;
    height: 100%;
    width: 16px;
    border-right: 1px solid color.$gray-200;
    z-index: 0;
}

.span_order {
    display: flex;
}

.crossed_out {
    text-decoration: line-through;
    margin-right: 4px;
}

.onboarding_section_content_properties {
    margin-top: 12px;
    border: 1px solid color.$gray-200;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: #ffffff;
    max-height: 283px;
    overflow-y: auto;
}
.onboarding_section_content_total {
    float: right;
    color: color.$gray-600;
    font-weight: 400;
}

.onboarding_section_content_properties_item {
    padding: 4px 8px;
    display: flex;
    gap: 12px;
    margin-top: 4px;

    .onboarding_section_content_properties_item_icon_wrapper {
        width: 32px;
        height: 32px;
        background-color: color.$gray-100;
        padding: 9.5px 7.5px;
        border-radius: 8px;
        line-height: 0;
        svg {
            height: 13px;
        }
    }
    .onboarding_section_content_properties_item_content {
        @include title.detail;
        width: calc(100% - 44px);
        p {
            &:last-child {
                color: color.$gray-600;
            }
        }
    }
}
