@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/content.module.scss' as ctt;
@use 'src/styles/title.module.scss' as title;
@use 'src/styles/input.module.scss' as it;

.change_plan_wrapper {
    width: 480px;
    max-height: calc(100vh - 10px);
    overflow-y: auto;

    @media (max-width: bp.$sm) {
        width: 350px;
    }
}

.change_plan_title_div {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px; 
    border-bottom: 1px solid color.$gray-200;
}

.change_plan_title {
    @include title.detail;
    font-weight: 500;
    line-height: 36px;
}

.change_plan_btnX {
    width: 36px;
    height: 36px;
    justify-content: center;
    display: flex;
    padding: 12px 0;
}

.change_plan_details {
    padding: 16px;
}

.properties_type {
    width: 100%;
}

.properties_type_title {
    @include title.detail;
    font-weight: 450;
}

.properties {
    width: 100%;
}

.properties_title_div {
    display: flex;
    justify-content: space-between;
}


.properties_list_div {
    height: auto;
    max-height: 208px;
    overflow-y: auto;
    border: 1px solid color.$gray-200;
    margin-top: 12px;
}

.properties_title {
    @include title.detail;
    font-weight: 450;
}

.properties_count {
    @include title.detail;
    color: color.$gray-600;
}

.div_seperate {
    padding-top: 13px;
    border-bottom: 1px solid color.$gray-200;
}

.billing {
    width: 100%;
    padding-top: 24px;
}

.billing_title {
    @include title.detail;
    font-weight: 450;
}

.total_info {
    width: 100%;
    padding-top: 24px;
}

.total_info_title {
    @include title.detail;
    font-weight: 450;
}

.confirm {
    @include bt.primary_button;
    
    margin-top: 24px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
}

.crossed_out {
    text-decoration: line-through;
    margin-right: 4px;
}