@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/content.module.scss' as ctt;

.topbar {
    @include ctt.content;
    padding: 20px 16px;

    @media (min-width: bp.$sm) {
        padding: 20px 32px;
    }

    @media (min-width: bp.$md) {
        padding: 20px 48px;
    }

    @media (min-width: bp.$lg) {
        padding: 20px 32px;
    }

    @media (min-width: bp.$xl) {
        padding: 20px 64px;
        margin: 0 auto;
    }
}

.topbar_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.topbar_content_buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.topbar_links {
    display: block;

    a {
        color: color.$gray-600;
        padding: 8px 0;
    }

    @media (max-width: bp.$sm) {
        display: none;
    }

    @media (min-width: bp.$sm) {
        display: flex;
        gap: 16px;
        margin-right: 24px;
    }

    @media (min-width: bp.$lg) {
        gap: 20px;
        margin-right: 32px;
    }
}

.topbar_hostwriter_links {
    display: flex;
    gap: 16px;

    a {
        color: color.$gray-600;
        padding: 8px 0;
    }
}

.topbar_menu {
    display: block;

    @media (min-width: bp.$sm) {
        display: none;
    }

    .topbar_menu_btn {
        min-width: 40px;
    }
}

.alink {
    a {
        color: color.$gray-600;
        text-decoration: none;    
    }
}


.topbar_button {
    @include bt.primary_button;
}

.topbar_second_button {
    @include bt.secondary_button;
}

.logo_wrapper {
    margin: 0;
    padding: 0;
    line-height: 0;
}

.logo {
    width: 86px;
    height: auto;
}
