@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/content.module.scss' as ctt;

.property_wrapper {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
}

.description_wrapper {
    width: 522px;
    border: 1px solid color.$gray-200;
    border-radius: 8px;
}

.description_only_title {
    @include title.detail; 
    font-weight: 500; 
    padding: 8px 16px;
    border-bottom: 1px solid color.$gray-200;
    display: flex;
    justify-content: space-between;
}

.description_only_title_txt {
    line-height: 36px;
}

.property_title {
    @include title.tlg;
    padding-bottom: 24px;
}

.property_description_wrapper {
    width: 280px;
    margin-left: 24px;
}

.property_description_div {
    border-radius: 8px; 
    border: 1px solid color.$gray-200;
}

.property_description_title {
    @include title.detail; 
    font-weight: 500; 
    padding: 16px;
    border-bottom: 1px solid color.$gray-200;
}

.property_description_details {
    padding: 16px;
    img {
        width: 248px;
        height: 128px;
        border-radius: 6px;
        object-fit: cover;
        word-break: break-all;
    }
}

.property_description {
    @include ctt.content;
    display: block;
    width: 100%;
}

.property_info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: bp.$sm) {
        padding: 0px 12px;
    }

    @media (min-width: bp.$md) {
        padding: 0px 16px;
    }

    @media (min-width: bp.$lg) {
        padding: 0px 20px;
        flex-wrap: nowrap;
    }

    @media (min-width: bp.$xl) {
        padding: 0px 80px 0 80px;
    }
}

.property_title {
    @include title.tlg;
}

.property_location {
    @include title.detail;
    color: color.$gray-600;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    padding-top: 20px;
}

.property_detail {
    @include title.detail;
    color: color.$gray-600;
}

.property_detail_item {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.icon2 {
    width: 16px;
    height: 13px;
    margin-right: 8px;
}

.description_title {
    @include title.tlg;
    margin-bottom: 10px;

    @media (min-width: bp.$sm) {
        padding: 0px 12px;
    }

    @media (min-width: bp.$md) {
        padding: 0px 16px;
    }

    @media (min-width: bp.$lg) {
        padding: 0px 20px;
        flex-wrap: nowrap;
    }

    @media (min-width: bp.$xl) {
        padding: 0px 80px 0 80px;
    }
}

.date_selector {
    border-radius: 8px;
}

.date_select {
    @include title.detail;
    font-weight: 450;
    padding: 8px 12px;
    margin-right: 0px;
    border-radius: 8px;
    background-color: transparent;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.info_panel {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    margin: 20px;
    padding: 20px;
    // min-height: 200px;
    width: calc(100% - 40px);
    max-width: 800px;
}

.info_panel_message {
    @include title.heading;
    font-size: 18px;
    color: color.$gray-600;
    text-align: center;
}

.property_description_btn {
    margin: 20px 16px 0 0;
    width: 100%;
    height: 36px;
    border-radius: 8px;
    border: 1px solid color.$gray-200;
    background-color: #fff;
}