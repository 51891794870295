@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/content.module.scss' as ctt;
@use 'src/styles/font.module.scss' as ft;

.description_only_wrapper {
    width: 100%;
    background-color: color.$purbple-050-v2;
    display: flex;
    justify-content: center;

    padding: 0 16px;
    @media (min-width: bp.$sm) {
        padding: 0 32px 32px 32px;
    }
    @media (min-width: bp.$md) {
        padding: 0 48px 32px 48px;
    }
}
.description_only {
    width: 100%;
    padding: 32px 16px;
    background-color: white;
    border: 1px solid color.$gray-200;
    border-radius: 8px;

    @media (min-width: bp.$sm) {
        padding: 32px;
    }
    @media (min-width: bp.$md) {
        padding: 48px;
    }
    @media (min-width: bp.$lg) {
        padding: 48px 64px;
        max-width: 928px;
    }

    @media (min-width: bp.$xl) {
        max-width: 960px;
    }
}

.description_section {
    margin-bottom: 32px;
    flex-wrap: wrap;
    gap: 8px;
}

.title {
    @include title.tlg;
    text-align: center;
    font-weight: 500;
    color: color.$purple-900;
}

.property_title {
    @include title.t3xl;
    text-align: center;
    margin-bottom: 2rem;
}

.section_title {
    @include title.txl;
    color: color.$purple-900;
}

.description_title {
    @include title.detail;
    color: color.$gray-900;
    font-weight: 450;
    width: 100%;
    margin-bottom: 16px;

    @media (min-width: bp.$lg) {
        width: 232px;
    }
}

.buttons_wrapper {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.toggleButton {
    @include bt.button;
    background-color: transparent;
    border: 1px solid color.$gray-200;
    cursor: pointer;
    margin-left: 8px;
    margin-bottom: 8px;
    transition: background-color 0.3s, border-color 0.3s;
    color: color.$gray-900;
    // font-size: 14px;
    line-height: 20px;
    width: calc(50% - 4px);
    text-align: left;
    margin: 0;
    display: flex;
    align-items: center;

    @media (min-width: bp.$sm) {
        width: auto;
    }
}

.toggleButtonSelected {
    background-color: color.$purple-100;
    border-color: color.$purple-400;
    color: color.$gray-900;
}

.colorIndicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 8px;
}

.toggleButtonText {
    display: inline-flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: clamp(10px, 3.5vw, 14px);
}