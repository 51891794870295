@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/content.module.scss' as ctt;
@use 'src/styles/input.module.scss' as it;

.generating {
    @include ctt.content;

    display: block;
    padding: 40px 16px 0 16px;
    gap: 32px;
    width: 100%;
    justify-content: center;

    @media (min-width: bp.$sm) {
        padding: 48px 32px 0 32px;
    }

    @media (min-width: bp.$md) {
        padding: 48px;
    }

    @media (min-width: bp.$lg) {
        flex-wrap: nowrap;
    }

    @media (min-width: bp.$xl) {
        padding: 48px 64px;
    }
}

.hero_desc_primary_btn {
    @include bt.primary_button;
    @include title.heading;
    line-height: 24px;
    padding: 12px 16px 12px 16px;
    margin: auto;
    background-color: color.$purple-400;
    border: #330476;
    display: flex;
    text-align: center;
    justify-content: center;
    @media (max-width: bp.$sm) {
    }
    @media (min-width: bp.$sm) {
    }
}

.hero_desc_detail {
    @include title.detail;

    text-align: center;
    color: color.$gray-900;
    margin: 20px auto 0 auto;

    @media (min-width: bp.$sm) {
        width: 576px;
    }

    @media (min-width: bp.$md) {
        width: 640px;
    }
}

.hero_desc_detail2 {
    @include title.detail;

    text-align: center;
    color: color.$gray-600;
    margin: 6px auto 18px auto;

    @media (min-width: bp.$sm) {
        width: 576px;
    }

    @media (min-width: bp.$md) {
        width: 640px;
    }
}

.spinner_icon {
    width: 16px;
    height: 24px;
    color: #ffffff;
    transform-origin: center;
    animation:rotate 3s linear infinite;
    @keyframes rotate {
        from {transform: rotate(0deg);}
        to {transform: rotate(360deg);}
    }
}
.button_desc_primarty_btn_txt {
    margin-left: 8px;
}

.email_input_container {
    width: 100%;
    background-color: white;
    border: 1px solid color.$gray-200;
    border-radius: 8px;
    align-items: center;
    flex-direction: column;
    display: flex;
    margin: auto;
    padding: 24px 16px 24px 16px;

    @media (min-width: bp.$sm) {
        padding: 24px;
        max-width: 576px;
    }
    @media (min-width: bp.$md) {
        padding: 24px;
        max-width: 672px;
    }
    @media (min-width: bp.$lg) {
        padding: 24px 64px;
        max-width: 928px;
    }
    @media (min-width: bp.$xl) {
        padding: 24px 64px;
        max-width: 960px;
    }
}

.email_input_title {
    @include title.heading;
    line-height: 24px;
    font-weight: 450;
    color: color.$gray-900;
    margin: 0 auto 0 auto;
}

.email_input_detail {
    @include title.heading;
    line-height: 24px;
    text-align: center;
    margin: 6 auto;
    padding: 0 0 0 0;
    color: color.$gray-700;

    @media (min-width: bp.$sm) {
        padding: 0 36px;
        max-width: 560px;
    }
}

.email_input_form {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (min-width: bp.$sm) {
        flex-direction: row;
    }
}

.email_input_input {
    @include it.input;
    flex: 1;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 12px;
    box-sizing: border-box;
    @media (min-width: bp.$sm) {
        margin-top: 24px;
        margin-bottom: 0;
        margin-right: 12px;
        max-width: 400px;
    }
}

.email_input_submit_button {
    @include bt.primary_button;
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;

    @media (min-width: bp.$sm) {
        margin-top: 24px;
        width: 78px;
    }
    @media (min-width: bp.$xl) {
        width: 86px;
    }
}
