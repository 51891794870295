@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/content.module.scss' as ctt;

.property_card {
    display: flex;
    cursor: pointer;
    padding: 8px;
    max-width: 100%;

    &:hover {
        background-color: color.$gray-100;
        border-radius: 8px;
    }
}

:global(.properties_list_div) {
    .property_info {    
        width: 345px;
    }
}
.property_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8px;
    width: 228px;
}

.property_image {
    width: 60px;
    height: 40px;
    border-radius: 6px;
    object-fit: cover;
    margin: 0 8px;
}

.property_title {
    @include title.detail;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.property_location {
    @include title.detail;
    color: color.$gray-500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
