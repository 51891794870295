@use 'src/styles/content.module.scss' as ctt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;

.info {
    @include ctt.content;
    flex-wrap: wrap;

    padding: 64px 16px 0px 16px;
    // background: linear-gradient(180deg, rgba(234, 227, 242, 0) 0%, color.$purbple-050-v2 100%);

    @media (min-width: bp.$sm) {
        padding: 64px 32px 0 32px;
    }

    @media (min-width: bp.$md) {
        padding: 64px 48px 0 48px;
    }

    @media (min-width: bp.$lg) {
        padding: 64px 32px 0 32px;
    }

    @media (min-width: bp.$xl) {
        padding: 64px 64px 0 64px;
    }
}

.info_content {
    width: 100%;
    max-width: 640px;

    @media (min-width: bp.$xl) {
        max-width: 762px;
    }
}

.info_content_title {
    @include title.t4xl;
    color: color.$gray-900;
    text-align: center;

    @media (max-width: bp.$sm) {
        text-align: left;
    }
    @media (min-width: bp.$xl) {
        @include title.t5xl;
    }
}

.info_content_detail {
    @include title.heading;
    color: color.$gray-600;
    text-align: center;
    line-height: 24px;
    margin-top: 16px;

    @media (max-width: bp.$sm) {
        text-align: left;
    }
    @media (min-width: bp.$sm) {
        @include title.tlg;
        line-height: 26px;
    }
}
